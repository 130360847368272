body {
  /* background-color: aqua; */
}
.title-color{
  color: rgb(0, 0, 255);
}
.secondary-title-color{
  color: #4b8ef1;
}
.main-color{
  color: rgb(75, 141, 241);
}
.nav-logo {
  width: 6em;
}
.link {
  text-decoration: none;
}
.main-banner {
  background-image: url("../images/slider-left-dec.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.title-tab{
  background: linear-gradient(-45deg, #23a5d500, #23a5d5aa, #23a5d5aa, #23a5d500);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
}

@media only screen and (max-width: 600px) {
  .main-banner {
    background-image: url("../images/client-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.quote-symbols{
  width: fit-content;
  margin: auto;
  position: relative;
}
.quote-symbols::before{
  content: '';
  position: absolute;
  display: block;
  top: -1em;
  left: 0em;
  font-size: 20px;
  width: 2em;
  height: 2em;
  background-position: center center;
  border-radius: 100%;
  background-color:#b4710e;
  filter:invert(100%);
  background-size: 1.2em;
  background-repeat: no-repeat;
  background-image: url(../images/double-quote-serif-left-svgrepo-com.svg);
}
.quote-symbols::after{
  content: '';
  position: absolute;
  display: block;
  top: -1em;
  right: 0em;
  font-size: 20px;
  width: 2em;
  height: 2em;
  background-position: center center;
  border-radius: 100%;
  background-color:#b4710e;
  filter:invert(100%);
  background-size: 1.2em;
  background-repeat: no-repeat;
  background-image: url(../images/double-quote-serif-right-svgrepo-com.svg);
}
.second-section {
  padding-top: 40px;
  background-image: url("../images/services-right-dec.png");
  background-repeat: no-repeat;
  background-position: 300px top;
  min-height: 270px;
}

.first-section,.about-us,.fourth-section,.fifth-section,.main-service,.course-section,.digital-marketing-collapse,.review-sec,.course-info-sec,.services-container,.digital-marketing-faq{
  padding-top: 50px;
}

.service-title{
  color:#4b8ef1;
}

.box-item{
  padding: 10px;
}

.small-banner {
  background-color: #4b8ef1;
  color: white;
}
.career-banner{
  position: relative;
  background-color: rgba(0, 0, 0, 0.644);
}
.career-banner::before{
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url(../images/carreer.svg);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.about-us-banner{
  position: relative;
  background-color: rgba(0, 0, 0, 0.644);
}
.about-us-banner::before{
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url(../images/about-banner.svg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.about-us-desc{
  max-width: 100%;
  max-height: 100%;
}

.main-title{
  padding-top:150px;
  color: #4b8ef1;
  font-family: var(--bs-body-font-family);
}

.sub-main-title{
  padding-top: 30px;
  color: white;
  word-wrap: normal;
  font-family: var(--bs-body-font-family);
}

.sub-title{
  padding-top: 30px;
  color: white;
  padding-bottom: 150px;
  font-family: var(--bs-body-font-family);
}

.about-us-card{
  padding-top: 50px;
}

.aboutus-card {
  background-image: url(../images/pro-table-top.png);
  background-repeat: no-repeat;
  margin: 1em;
  -webkit-box-shadow: 10px 10px 28px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px -15px rgba(0, 0, 0, 0.75);
  /* color: #4b8ef1; */
}
.flex-both-axis-center{
  display: flex;
	align-items: center;
	justify-content: center;
  flex-wrap: wrap;
}
.card-flex-item{
  width: 500px;
}
.service-banner{
  background-size: contain;
  background-repeat: no-repeat; 
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}

.service-banner::before{
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url(../images/service-banner.jpg);
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.courses-banner{
  background-size: contain;
  background-repeat: no-repeat; 
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}

.courses-banner::before{
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url(../images/online-course.svg);
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.digital-marketing-banner{
  background-size: contain;
  background-repeat: no-repeat; 
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.digital-marketing-banner::before{
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:cover;
  background-repeat: no-repeat;
  background-image: url(../images/digital-marketing-service.jpg);
  background-position: center center;
  z-index: -1;
}
.web-design-banner{
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.web-design-banner::after{
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:contain;
  background-repeat: no-repeat;
  background-image: url(../images/web-design.jpg);
  background-position: center center;
  z-index: -1;
}

.web-development-banner{
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.web-development-banner::after{
  background-image: url(../images/website-development.jpg);
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.video-graphic-banner{
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.video-graphic-banner::after{
  background-image: url(../images/video-graphic.jpg);
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.content-marketing-banner{
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.content-marketing-banner::after{
  background-image: url(../images/content-marketing.jpg);
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}


/* kjfhklajsndglknalkngn */


.stock-market-course{
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.stock-market-course::after{
  background-image: url(../images/website-development.jpg);
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}



.stock-marketing-banner{
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.stock-marketing-banner::after{
  background-image: url(../images/stock.svg);
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}
.content-marketing-banner{
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}
.content-marketing-banner::after{
  background-image: url(../images/content-marketing.jpg);
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.services-flex{
  flex-wrap: wrap;
  
}
.services-card{
  background-image: url(../images/pro-table-top.png);
  background-repeat: no-repeat;
  -webkit-box-shadow: 10px 10px 28px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px -15px rgba(0, 0, 0, 0.75);
  /* color: #4b8ef1; */
}
.dinamic-card{
  background: linear-gradient(-45deg, #23a6d5, #ffffff, #ffffff, #23a6d5);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
  border: 2px solid blue !important;
  max-width: 400px;
  min-height: 400px;
  margin: 0px 1em 1em 0px;
  /* color: #4b8ef1; */
}
.custom-card-container{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
  
}
.service-hero{
  background: rgb(75,142,241);
  background: linear-gradient(149deg, rgba(75,142,241,1) 0%, rgba(248,248,248,0.6617997540813201) 57%);
}

.career-cards{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 3em;
}
.career-card{
  /* background-image: url(../images/pro-table-top.png); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: top;
  background-position-y: left;
  color: #4b8ef1;
  /* width: 400px;
  height: 400px; */
  border: 2px solid #4b8ef1 !important;
  background-color: white !important;
  margin: 0px 1em 1em 0px;
}
.contact-us-banner{
  background-size: contain;
  background-repeat: no-repeat; 
  background-color: rgba(0, 0, 0, 0.749);
  position: relative;
}

.contact-us-banner::before{
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-image: url(../images/contact_us.svg);
  background-size:contain;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.map{
  min-height: 21em;
}
/* .career-card:hover{
  background-color: #4b8ef1 !important;
  transition: all 0.2s;
} */
/* *{
  border: 1px solid black;
} */


/* Footer */

.text-reset:hover{
  color: #212529 !important;
}


.home-cards-service{
  background: linear-gradient(-45deg, #23a6d5, #ffffff, #ffffff, #23a6d5);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
  margin: 1em;
  border: 2px solid blue !important;
}
.home-cards-courses{
  background: linear-gradient(-45deg, #23a6d5, #ffffff, #ffffff, #23a6d5);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
  margin: 1em;
  border: 2px solid blue !important;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.card-icon{
  font-size: 2em;
  color: #23a6d5;
  margin: 0px;
  padding: 0px;
}


.error{
  background: radial-gradient(circle, #ffffff 40%, #7cc7e0 60%);
  display: grid;
  place-items: center;
  min-height: 100vh;
  padding: 1em;
	animation: radiate 3s ease infinite;
  transition: all 3s;
}
@keyframes radiate {
	0% {
    background: radial-gradient(circle, #ffffff 40%, hsl(195, 72%, 68%) 60%);

	}
	50% {
    background: radial-gradient(circle, #ffffff 50%, hsl(195, 82%, 68%) 70%);

	}
	100% {
    background: radial-gradient(circle, #ffffff 60%, hsl(195, 92%, 68%) 80%);

	}
}
.error.h1{
  font-size: 50px;
  text-align: center;
}
.error img{
  border-radius: 100%;
  width: 100%;
}
.error.p{
  font-size: 20px;
  text-align: center;
}
.error.div{
  text-align: center;
}
.error.que-mark{
  width: 300px;
  height: 300px;
}

.border-card{
  border: 5px solid rgb(75, 142, 241);
  border-radius: 10px;
  padding: 1em;
}

.video-responsive {
  margin-top: 58px;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}